span {
    width: auto;
    text-align: center
}

.loader {
    width: 80px;
    height: 80px
}

#uploadProgress, .loader {
    position: absolute;
    z-index: 1
}

#uploadProgress {
    width: 450px;
    height: 45px
}

.progress-bar {
    color: #000;
    font-size: 24px;
    font-weight: 400
}

.btn {
    height: 40px;
    display: inline-flex;
    align-items: center
}

.fullHeight {
    height: 100%
}

td, th {
    vertical-align: middle !important;
    text-align: center;
    padding: 0 !important
}

.menuButton, .submitButton {
    display: block !important
}

table {
    width: 100%;
    white-space: nowrap
}

.btn-secondary {
    width: 100% !important;
    display: block !important
}

.midleColumnWidth {
    width: auto;
    min-width: 120px;
    max-width: 15vw;
    padding: 0 10px !important
}

.middleColumnWidth, .smallColumnWidth {
    width: auto;
    min-width: 80px;
    max-width: 10vw;
    padding: 0 10px !important
}

.bigColumnWidth {
    width: 21vw;
    min-width: 160px;
    max-width: 20vw
}

.rowWidth {
    width: 75vw;
    min-width: 680px;
    max-width: 75vw
}

.table {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%
}

table th {
    word-break: break-word;
    white-space: normal !important
}

.homeDiv {
    display: flex
}

.homeMainDiv {
    margin: 0;
    width: 85%
}

.buttonGroup {
    width: 100%
}

.btn-left {
    text-align: left !important
}

.createUploadDivBackground {
    background-color: #d3d3d3
}

.btnBottomMargin {
    margin-bottom: 10px
}

.uploadBtn {
    margin-left: 20px
}

.invisible {
    display: none !important
}

.homeRowDiv {
    display: flex;
    flex-direction: row;
    width: 100%
}

.homeRowSubDiv {
    width: auto !important
}

.maxWidth {
    width: 100%
}

.standardMargins {
    margin: 5px 10px 5px 5px
}

.columnDiv, .verticalCenter {
    display: flex;
    flex-direction: column
}

.verticalCenter {
    justify-content: center
}

.imgSize {
    width: 20px;
    height: 20px
}

.horizontalMargins {
    margin-left: 3px;
    margin-right: 3px
}

li {
    list-style: none
}

.folderNameDiv {
    display: flex;
    font-weight: 700
}

.btnWithoutPadding {
    padding: 0
}

.firstTreeHeaderWidth {
    width: 60%
}

.treeHeaderWidth {
    width: 10%
}

.treeHeadersDiv {
    display: flex
}

.container {
    max-width: none !important
}

.midleLefMargin {
    margin-left: 10px
}

.colMinWidth {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content
}

.flexColumn {
    display: flex;
    flex-direction: column
}

.flexRow {
    display: flex;
    flex-direction: row
}

.fullSize, .fullWidth {
    width: 100%
}

.fullSize {
    height: 100%
}

.mediumPaddings {
    padding: 10px
}

.mediumRightMargin {
    margin-right: 50px
}

.mediumBottomMargin {
    margin-bottom: 30px
}

.bottomLine {
    height: 1px;
    width: 100%
}

.hidden {
    visibility: hidden
}

.textCenter {
    display: block !important
}

.fileActionsButton {
    width: 80px;
    display: block
}

.navBarDiv {
    background-color: #f8f9fa;
    width: 15vw
}

.navbarLinksDiv {
    display: flex;
    flex-direction: column
}

.activeMenuOption {
    background-color: #e6e6fa
}

.headerLogoDiv {
    margin-bottom: 10px
}

.headerLogoImg {
    width: 200px;
    height: 55px;
    margin: 5px 0 0 5px
}

.smallColumn {
    width: 10%
}

.bigColumn {
    width: 75%
}

.rowDiv {
    display: inline-block
}

.inactiveLink {
    color: grey !important
}

.btnMarginLeft {
    margin-left: 10px
}

.checkbox {
    width: 30px;
    height: 30px
}

.checkboxIput {
    margin: 10px 0
}

.rowLoader {
    height: 100vh
}

.columnLoader, .rowLoader {
    display: flex;
    justify-content: center
}

.columnLoader {
    flex-direction: column
}

.sortImage {
    cursor: pointer;
    height: 5px;
    width: 10px;
    margin-left: 10px
}

.filterImage {
    height: 13px
}

.toolTipMargin {
    margin: 10px 0
}

.toolTipElementSize {
    height: 30px;
    width: 100%
}

.searchImage {
    width: 30px;
    height: 26px;
    position: absolute;
    left: 135px;
    top: 77px;
    padding: 2px 0
}

.tooltip-inner {
    min-width: 160px;
    width: 260px;
    max-width: 260px;
    height: 140px
}

.tooltipButtonsDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 8px
}

.btn-light {
    display: flex;
    justify-content: center
}

.loginForm {
    width: 450px;
    top: 30%;
    left: 40%;
    position: absolute;
    display: flex;
    flex-direction: column
}

.loaderRow {
    height: 100vh;
    width: 100vw
}

#uploadProgressBar, .loaderRow {
    display: flex;
    justify-content: center
}

#uploadProgressBar2, .loaderColumn {
    display: flex;
    flex-direction: column;
    justify-content: center
}

#uploadProgressBar2 {
    height: 100vh
}

.invicible {
    display: none
}

.additionalRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end
}

.inputSize {
    height: 40px;
    width: 100%
}

.registrationForm {
    width: 450px;
    top: 30%;
    left: 40%;
    position: absolute;
    display: flex;
    flex-direction: column
}

.header {
    margin-bottom: 10px
}

.inputSizeDiv {
    width: 100%
}

.submitButton {
    width: 100%;
    margin-top: 10px
}

.registrationSearchDiv {
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between
}

.dropdown-toggle, .dropdownSize {
    width: 100%
}

.dropdownLabelDiv {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.redirectDiv {
    margin-top: 10px
}

.usersTableWidth {
    width: auto
}

.usersTableMargin {
    margin: 10px
}
